.carStorage_datePicker {
  width: 260px;
  height: 60px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

.carStorage_datePicker:focus,
.carStorage_datePicker:hover {
  border: 1px solid #dee2e6;
}

.carStorage_select > .ant-select-selector {
  padding-left: 18px !important;

  font-size: 18px !important;
  font-weight: 400 !important;
}

.carStorage_select .ant-select-selection-placeholder {
  color: #dee2e6 !important;
  letter-spacing: -0.72px !important;
}

.ant-select-item {
  width: 100% !important;
}
