.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff6a1f !important;
  border-color: #ff6a1f !important;
  border-radius: 3px;
  outline: none !important;
}

.ant-checkbox-inner {
  border-radius: 3px;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner,
.ant-checkbox-checked:hover,
.ant-checkbox-checked:focus,
.ant-checkbox-inner::after {
  background-color: #ff6a1f;
  border-color: #ff6a1f;
}

.ant-checkbox {
  outline: none !important;
  display: none;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-input:hover {
  border-color: none !important;
  outline: none !important;
}

.ant-checkbox-checked:after {
  border: none !important;
}

.ant-picker-date-panel,
.ant-picker-month-panel,
.ant-picker-year-panel {
  align-items: center;
  justify-content: center;
  width: 522px;
  border-radius: 10px !important;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}

.ant-picker-body {
  display: flex;

  align-items: center;
  justify-content: center;
}

.ant-picker-year-btn,
.ant-picker-month-btn {
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
  font-family: SpoqaHanSansNeo;
}

.ant-picker-header-view > button:hover {
  color: #000 !important;
}

.ant-picker-header-view {
  color: #000 !important;
}

.ant-picker-content {
  margin-top: 15px !important;
  margin: 0;
}

.ant-picker-date-panel .ant-picker-content th {
  width: 60px;
}

.ant-picker-header {
  border: none;
  margin-top: 10px;
}

.ant-picker-input {
  outline: none !important;
  cursor: pointer;
}

.ant-picker-input > input {
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  outline: none !important;
}

.ant-picker-input > input::placeholder {
  color: #e9ecef !important;
}

.ant-picker-focused {
  border-color: #000000;
  box-shadow: none;
}

.ant-picker-focused:hover {
  border-color: #000000;
  box-shadow: none;
}

.ant-picker:hover {
  border-color: #000000;
  box-shadow: none;
}

.ant-picker {
  cursor: pointer;
  border-color: #fff;
  box-shadow: none;
}
.ant-picker-input:hover {
  border-color: #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #fff;
  background: #ff6a1f;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border: 1px solid #ff6a1f;
}

.pickUpDatePicker {
  outline: none;
  margin-top: 16px;
  width: 522px;
  height: 60px;
  padding-left: 16px;
  border-radius: 10px;
  border: 1px solid #e9ecef;
  color: #000;
  font-size: 18px;
}

.pickUpDatePicker:hover,
.pickUpDatePicker:focus {
  border: 1px solid #333;
}

/* Steps */
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #ff6a1f !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #ff6a13 !important;
}

.ant-steps-item-title {
  font-size: 18px;
  font-family: SpoqaHanSansNeo !important;
  font-weight: 400;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #ffd0b8 !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail:after {
  background-color: #ffd0b8 !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #ffd0b8 !important;
}

/* 모달 */

.image_modal {
  width: 682px !important;
}

.ant-modal-header {
  background-color: #212529;
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal-title {
  font-size: 20px;
  font-weight: bold;
  font-family: SpoqaHanSansNeo;
  color: #fff;
}

.ant-modal-close-x {
  display: block;
  color: #fff;
  font-weight: bold;
  margin-top: 4px;
  font-size: 24px;
}

.ant-modal-content {
  border-radius: 10px;
}

.review_modal > .ant-modal-content {
  border-radius: 0;
}

.ant-modal-body {
  padding: 0;
}

.ant-modal-footer {
  padding: 0;
  height: 95px;
}

.ant-btn {
  display: none;
}

@media (max-height: 761px) {
  .ant-modal-body,
  .ant-modal-content {
    height: auto !important;
  }
}

.ant-select-selector {
  outline: none;
  border: 1px solid #dee2e6;
  box-shadow: none !important;
}

.ant-select-selection-search-input {
  width: 100%;
  overflow: scroll !important;
}

.bankOption::selection {
  background-color: rgba(255, 106, 19, 0.2);
}

.bankOption:hover {
  background-color: rgba(255, 106, 19, 0.2);
  color: #ff6a13;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}

.ant-select-selection-item {
  color: #212529;
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.72px;
}

.ant-select-selectopen > .ant-select-selection-item {
  background-color: rgba(255, 106, 19, 0.2) !important;
}

.ant-select-item-option-selected {
  background-color: rgba(255, 106, 19, 0.2) !important;
  color: #ff6a13 !important;
}

.ant-table-content {
  position: relative !important;
}

.option_div > .ant-select-selection-item {
  color: #dee2e6 !important;
}

.option_div > .ant-select-focused > .ant-select-selector {
  border: 1px solid #ff6a13 !important;
}

.option_div > .ant-select-open {
  border-radius: 10px !important;
}

.option_div > .ant-select-selectopen > .ant-select-selection-item {
  background-color: rgba(255, 106, 19, 0.2) !important;
  color: #ff6a13 !important;
}

.option_div > .ant-select-item-option-selected {
  color: #ff6a13 !important;
  background-color: rgba(255, 106, 19, 0.2) !important;
}

.ant-select-single {
  padding: 0 !important;
}

.option_div .ant-select-item-option-selected {
  background-color: rgba(255, 106, 19, 0.2) !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  border: 1px dotted #ff6a13;
  border-style: dotted !important;
  background-color: transparent;
  /* width: 146px !important;
  height: 2px !important; */
}

.ant-steps-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #ff6a13;
  background-color: #ff6a13;
  height: 24px;
  width: 24px;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.ant-dropdown-menu-item:hover {
  background-color: #ffd0b8;
}

.ant-dropdown-menu-item {
  text-align: left;
}

.myPage_div .ant-tabs-tab-btn {
  font-size: 18px !important;
}

.myPage_div .ant-tabs-ink-bar {
  border-bottom: 2px solid #ff6a13;
}

.ant-input-textarea {
  border-radius: 10px !important;
}

.ant-input {
  border-radius: 10px;
  border: 1px solid #dee2e6;
  font-size: 18px;
  padding-top: 16px;
  padding-left: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: #212529;
}

.ant-input:hover,
.ant-input:focus {
  border-color: #dee2e6 !important;
}

/* 헤더 메뉴 */
.header_menu > .ant-dropdown-menu {
  margin-top: 12px;
  width: 108px;
  height: 78px;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 5px;
  padding: 0;
}

.header_menu > .ant-dropdown-menu > .ant-dropdown-menu-item {
  padding: 9px 12px;
}
