.ant-pagination-item-link {
  border: none !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link:hover {
  color: #212529;
}

.ant-pagination-next button:hover {
  color: #212529 !important;
}

.ant-pagination-item-active:hover,
.ant-pagination-item-active {
  border: none;
}

.ant-pagination-item-active a,
.ant-pagination-item-active a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-position: under;
  color: #212529 !important;
  font-weight: 700 !important;
}

.ant-pagination-item {
  border: none;
}

.ant-pagination-item a {
  font-weight: 500;
  color: #868e96;
}

.ant-pagination-item a:hover {
  color: #212529 !important;
  font-weight: 700;
}

.ant-pagination-options > .ant-select > .ant-select-selector {
  display: none;
}

.ant-pagination-options > .ant-select > .ant-select-arrow {
  display: none;
}
