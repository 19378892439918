.bank_select
  > .ant-select-selector
  > .ant-select-selection-search
  ~ .ant-select-selection-placeholder {
  padding-left: 7px;
  color: #dee2e6;
  font-size: 18px;
  letter-spacing: -0.72px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
}

.bank_select {
  margin-top: 20px;
}

.bank_select > .ant-select-selector > .ant-select-selection-item {
  padding-left: 7px;
}

/*  슬라이더 */
.advertising_slider > .ant-slider-rail {
  height: 10px;
}

.advertising_slider > .ant-slider-track {
  background-color: #ffd0b8;
  height: 10px;
  border-radius: 10px;
  top: 6px;
}

.advertising_slider > .ant-slider-track:hover {
  background-color: #ffd0b8;
  height: 10px;
  border-radius: 10px;
}

.advertising_slider > .ant-slider-track:focus {
  background-color: #ffd0b8;
}

.advertising_slider > .ant-slider-handle {
  top: 9px;
  border: 2px solid #ff6a13;
  background-color: #ff6a13;
}

.advertising_slider > .ant-slider-handle:hover {
  border: 2px solid #ff6a13;
  background-color: #ff6a13;
  box-shadow: 0;
}

.advertising_slider > .ant-slider-handle:focus {
  box-shadow: none;
  border-color: none;
}

.advertising_slider
  > .ant-slider:hover
  .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #ffd0b8;
}
.advertising_slider > .ant-slider-dot-active {
  border-color: #ff6a13;
}

.advertising_slider > .ant-slider:hover .ant-slider-track {
  background-color: #ffd0b8;
}

.advertising_slider > .ant-slider-handle-click-focused {
  background-color: #ff6a13;
}

.advertising_slider > .ant-tooltip-open {
  background-color: #ffd0b8;
}

.advertising_slider > .ant-slider-step {
  height: 10px;
  top: 6px;
}

.advertising_slider
  > .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #ffd0b8;
  box-shadow: none;
}

.selfsellcar_checkbox > .ant-checkbox > .ant-checkbox-input:focus {
  outline: none;
  border-color: #d9d9d9;
}

.selfsellcar_checkbox > .ant-checkbox > .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  outline: none;
}

.selfsellcar_checkbox > .ant-checkbox > .ant-checkbox-inner::after {
  left: 32.5%;
}

.selfsellcar_checkbox > .ant-checkbox {
  border: none;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #d9d9d9 !important;
}
