.filter_menu > .ant-dropdown-menu {
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0;
  margin-top: 12px;
}

.filter_menu > .ant-dropdown-menu .ant-dropdown-menu-item {
  border-radius: 5px;
}

.filter_menu
  > .ant-dropdown-menu
  > .ant-dropdown-menu-item
  > .ant-dropdown-menu-title-content
  > label:hover {
  color: #ff6a13;
  cursor: pointer;
}

/* 셀렉트 박스 */
.brand_select > .ant-select-selector {
  width: 336px;
  height: 40px !important;
  padding: 5px 10px 0 10px !important;
}

.brand_select > .ant-select-selector .ant-select-selection-placeholder {
  font-family: SpoqaHanSansNeo;
  font-size: 16px;
  font-weight: 400;
  color: #dee2e6;
  letter-spacing: -0.64px;
}

.brand_select > .ant-select-selector > .ant-select-selection-item {
  font-family: SpoqaHanSansNeo;
  font-size: 16px;
  font-weight: 400;
  color: #212529;
  letter-spacing: -0.64px;
}

.brand_dropdown {
  padding: 0;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.ant-select-item {
  width: 336px;
  height: 40px;
  border-radius: 5px;
  align-items: center;
}

.ant-select-item:hover {
  background-color: rgb(255, 106, 19, 0.2) !important;
}

.ant-select-item-option-content:hover {
  color: #ff6a13 !important;
}

.rc-virtual-list-scrollbar-thumb {
  background: #e9ecef !important;
  height: 46px !important;
  width: 10px !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgb(255, 106, 19, 0.2) !important;
}

/* 계산기 셀렉트 */
.calculator_select > .ant-select-selector {
  border: none !important;
  height: 100% !important;
  padding-top: 0 !important;
}

.calculator_dropdown {
  padding: 0 !important;
  text-align: right;
  border-radius: 10px !important;
  width: 180px !important;
  height: 177px !important;
  box-shadow: 0px 3px 15px #0000000d !important;
  overflow-y: scroll;
}

.calculator_dropdown
  > div
  > .rc-virtual-list
  > .rc-virtual-list-holder
  > div
  > .rc-virtual-list-holder-inner
  .ant-select-item {
  width: auto !important;
  padding-right: 30px !important;
}
.calculator_dropdown
  > div
  > .rc-virtual-list
  > .rc-virtual-list-holder
  > div
  > .rc-virtual-list-holder-inner
  .ant-select-item
  > .ant-select-item-option-content:hover
  > .ant-select-item-option-state:hover {
  color: #ff6a13 !important;
}

.calculator_dropdown
  > div
  > .rc-virtual-list
  > .rc-virtual-list-holder
  > div
  > .rc-virtual-list-holder-inner
  .ant-select-item
  > .ant-select-item-option-content {
  color: #212529 !important;
  font-family: SpoqaHanSansNeo !important;
  font-size: 16px !important;
  letter-spacing: -0.64px !important;
  font-weight: 500 !important;
}
