.TabBarStyles .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 18px !important;
  font-weight: 500;
}

.TabBarStyles {
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: -0.72px;
}

.ant-tabs-ink-bar {
  background-color: transparent;
}

.ant-tabs-tab-btn:active {
  color: #ff6a13 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff6a13;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.8px;
}

.ant-tabs-tab-btn {
  font-size: 20px;
  color: #868e96;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
}

.ant-tabs-tab:hover {
  color: #ff6a13 !important;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #ff6a13;
}

.ant-select-selector {
  border-color: #dee2e6 !important;
  border-radius: 10px !important;
  padding-top: 16px !important;
  height: 60px !important;
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  outline: none;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.96px;
  font-family: SpoqaHanSansNeo;
}
.ant-select-selection-item:hover {
  border-color: #fff;
}

.ant-radio-wrapper {
  margin-right: 50px;
}

.ant-radio-wrapper > span {
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
}

.ant-radio-inner {
  width: 24px;
  height: 24px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #ff591c !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #ff591c;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #ff591c;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

textarea {
  resize: none;
}

.ant-input:focus {
  border-color: #cccccc;
  box-shadow: none;
}

.ant-input:hover {
  border-color: #cccccc;
  box-shadow: none;
}
