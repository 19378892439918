.ant-collapse {
  background-color: transparent;
  border: none;
}

.ant-collapse-header {
  padding-left: 0 !important;
  height: 72px;
  padding-top: 22px !important;
}

.ant-collapse-content-box {
  background-color: rgb(255, 208, 184, 0.1);
  height: 192px;
  max-height: 248px;
}

.my_page_picker {
  width: 150px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  cursor: pointer !important;
}

.my_page_picker > .ant-picker-input > input {
  font-size: 16px;
  cursor: pointer;
}

.my_page_picker:hover {
  border-color: #dee2e6;
}

.my_page_picker > .ant-picker-input > .ant-picker-suffix {
  display: none;
}

.ant-picker-input {
  font-size: 16px !important;
}

.ant-picker-input > input::placeholder {
  color: #dee2e6 !important;
  font-weight: 400;
  font-size: 16px !important;
}

.ant-picker-active-bar {
  display: none;
}

.ant-picker-range-separator {
  display: none;
}

.ant-tabs-nav-operations {
  display: none !important;
}
