.auction_datepicker > .ant-picker-panel-container {
  width: 336px;
  height: 315px;
  margin-top: 30px;
}

.auction_picker > .ant-picker-input {
  display: none;
}

.liveAuction_table {
  margin-top: 50px;
}

.ant-table-thead > tr > th {
  background-color: #fff;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  display: none;
}

.auction_table_row {
  height: 67px;
  border-bottom: 1px solid #ededed !important;
}

.auction_table_row2 {
  height: 92px;
}

.ant-table-tbody > tr > td {
  vertical-align: middle;
}

.liveAuction_tab {
  width: 100%;
}

.liveAuction_tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  font-size: 24px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.96px;
  font-weight: 500;
}

.liveAuction_tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  font-size: 24px;
  letter-spacing: -0.96px;
}

.auction_list_picker {
  border-radius: 10px;
  border: 1px solid #dee2e6;
  width: 150px;
  height: 40px;
}

.auction_list_picker:hover {
  border-color: #dee2e6;
}

.auction_list_picker > .ant-picker-input > input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.64px;
  cursor: pointer;
}

/* 리액트 캘린더 */
.react-calendar {
  margin-top: 30px !important;
  padding: 20px 35px;
  width: 336px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 3px 15px #0000000d;
}

.react-calendar__navigation button {
  color: #ff6a13;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
  padding: 0;
}

.react-calendar__navigation__label {
  font-size: 16px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
}

.react-calendar__month-view__weekdays__weekday > abbr[title] {
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  letter-spacing: -0.64px;
  color: #868e96;
  text-decoration: none;
}

.react-calendar__tile .react-calendar__month-view__days__day > abbr {
  font-size: 16px !important;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
}

.react-calendar__month-view__days__day {
  position: relative;
}

.react-calendar__tile--now {
  color: #212529;
  border: 1px solid #ff6a13;
  background-color: transparent !important;
  border-radius: 100%;
}

.react-calendar__tile--active {
  background-color: #ff6a13 !important;
  border-radius: 100%;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ff6a13;
  border-radius: 100%;
  /* color: #fff; */
}

.react-calendar__tile--active:enabled:hover {
  background-color: #ff6a13;
  border-radius: 100%;
}

.react-calendar__navigation button:enabled:hover {
  background-color: transparent;
}

.react-calendar__navigation button:disabled {
  background-color: transparent;
}

/* 셀렉트 박스 */
.auction_select > .ant-select-selector {
  height: 40px !important;
  padding-top: 6px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.auction_select > .ant-select-selector > .ant-select-selection-placeholder {
  font-size: 16px !important;
  letter-spacing: -0.64px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #dee2e6;
}
