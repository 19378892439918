.google_button {
  padding: 0;
  box-shadow: none !important;
  display: flex;
  justify-content: center !important;
  border: none;
  opacity: 1 !important;
}

.google_button > span {
width : 70px;
height : 70px;
padding : 0 !important;
background-color: #fc5244 !important;
opacity: 1 !important;
border-radius : 100%;
}

.google_button:hover {
  opacity: 1 !important;
}

.google_logo {
  background-color: #fc5244 !important;
  opacity: 1 !important;
}

.google_button > div:first-child {
  display: none;
}

#naverIdLogin > #naverIdLogin_loginButton > img {
  border-radius: 100%;
  width : 70px;
  height : 70px;
}

#naverIdLogin {
  height : 70px !important;
}